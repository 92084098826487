.rb-drag-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow: hidden;
  pointer-events: none;
}

.rb-drag-view svg {
  position: absolute;
  overflow: visible;
}

.rb-drag-border {
  position: absolute;
  border: 2px dashed #aaa;
  border-radius: 6px;
}
