.EnterNew {
  position: relative;
  margin-right: 61px;
  cursor: pointer;
}

.imgHolder {
  width: 160px;
  height: 160px;
  position: relative;
}

.shadow {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000000;
  border-radius: 12px;
  transition: background 0.2s ease;
}

.img {
  width: 100%;
  height: 160px;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 40, 183, 0.08);
}

.img:hover {
  filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.1));
}

.img:active {
  filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.1));
}

.img:active p {
  background: #00000033;
}

.imgInner {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.imgNew {
  width: 72px;
  height: 72px;
  padding: 44px;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}

.imgNew:hover {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

.imgNew svg rect {
  fill: #bfe0ff;
}

.imgNew:hover svg rect {
  fill: #95c9fa;
}

.imgNew:active svg rect {
  fill: #45a5ff;
}

.imgNew:active {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

.state {
  width: 62px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  background: #58b65a;
}

.stateText {
  /*position: absolute;*/
  display: block;
  color: #ffffff;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  height: 14px;
  /*vertical-align: center;*/
  margin: 6px auto;
}

.text {
  width: 80px;
  height: 28px;
  margin-top: 21px;
  margin-left: 40px;
  margin-right: 40px;

  color: #000000;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
