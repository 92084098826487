.full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flower {
  width: 345px;
  height: 345px;
  position: absolute;

  transition: all .15s ease;
  transform: scale(0.1) rotateZ(90deg);
}

.flowerShow {
  transform: scale(1.0) rotateX(0deg);
}

.flower img {
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  width: 54px;
  transform: translate(-50%, -50%);
}

.text p {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  color: #ffffff;
}

.petal {
  fill: #f99a43;
  stroke: white;
  stroke-width: 4;
  pointer-events: visible;
}
.petal:hover {
  cursor: pointer;
  fill: #ff8a22;
}
.petal:active {
  fill: #ff7e0c;
}

.petalEmpty {
  fill: #fcbf89;
  pointer-events: none;
}

.scroll {
  -webkit-transition: all .2s ease .2s;
  -moz-transition: all .2s ease .2s;
  transition: all .2s ease .2s;
}

.scroll:first-child {
  -webkit-transform: skew(62deg);
  -moz-transform: skew(62deg);
  -ms-transform: skew(62deg);
  transform: skew(62deg);
}

.scroll:nth-child(2) {
  -webkit-transform: rotate(30deg) skew(62deg);
  -moz-transform: rotate(30deg) skew(62deg);
  -ms-transform: rotate(30deg) skew(62deg);
  transform: rotate(30deg) skew(62deg);
}

.scroll:nth-child(3) {
  -webkit-transform: rotate(60deg) skew(62deg);
  -moz-transform: rotate(60deg) skew(62deg);
  -ms-transform: rotate(60deg) skew(62deg);
  transform: rotate(60deg) skew(62deg);
}

.scroll:nth-child(4) {
  -webkit-transform: rotate(90deg) skew(62deg);
  -moz-transform: rotate(90deg) skew(62deg);
  -ms-transform: rotate(90deg) skew(62deg);
  transform: rotate(90deg) skew(62deg);
}

.scroll:nth-child(5) {
  -webkit-transform: rotate(120deg) skew(62deg);
  -moz-transform: rotate(120deg) skew(62deg);
  -ms-transform: rotate(120deg) skew(62deg);
  transform: rotate(120deg) skew(62deg);
}

.scroll:nth-child(6) {
  -webkit-transform: rotate(150deg) skew(62deg);
  -moz-transform: rotate(150deg) skew(62deg);
  -ms-transform: rotate(150deg) skew(62deg);
  transform: rotate(150deg) skew(62deg);
}

.scroll:nth-child(7) {
  -webkit-transform: rotate(180deg) skew(62deg);
  -moz-transform: rotate(180deg) skew(62deg);
  -ms-transform: rotate(180deg) skew(62deg);
  transform: rotate(180deg) skew(62deg);
}

.scroll:nth-child(8) {
  -webkit-transform: rotate(210deg) skew(62deg);
  -moz-transform: rotate(210deg) skew(62deg);
  -ms-transform: rotate(210deg) skew(62deg);
  transform: rotate(210deg) skew(62deg);
}

.scroll:nth-child(9) {
  -webkit-transform: rotate(240deg) skew(62deg);
  -moz-transform: rotate(240deg) skew(62deg);
  -ms-transform: rotate(240deg) skew(62deg);
  transform: rotate(240deg) skew(62deg);
}

.scroll:nth-child(10) {
  -webkit-transform: rotate(270deg) skew(62deg);
  -moz-transform: rotate(270deg) skew(62deg);
  -ms-transform: rotate(270deg) skew(62deg);
  transform: rotate(270deg) skew(62deg);
}

.scroll:nth-child(11) {
  -webkit-transform: rotate(300deg) skew(62deg);
  -moz-transform: rotate(300deg) skew(62deg);
  -ms-transform: rotate(300deg) skew(62deg);
  transform: rotate(300deg) skew(62deg);
}

.scroll:nth-child(12) {
  -webkit-transform: rotate(330deg) skew(62deg);
  -moz-transform: rotate(330deg) skew(62deg);
  -ms-transform: rotate(330deg) skew(62deg);
  transform: rotate(330deg) skew(62deg);
}

.text {
  pointer-events: none;
}
.text:nth-child(1) {
}
.text:nth-child(2) {
}
.text:nth-child(3) {
}
.text:nth-child(4) {
}
.text:nth-child(5) {
}
.text:nth-child(6) {
}
.text:nth-child(7) {
}
