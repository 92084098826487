.holder {
  position: absolute;
  top: 51px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.toolbox {
  position: absolute;
  top: 36px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.toolbox::-webkit-scrollbar {
  width: 6px;
  background: #00000000;
}

.toolboxInner span{
  display: block;
  text-align: center;
  color: #bbbbbb;
  transform: translateY(-76px);
  white-space: nowrap;
}

.toolboxInner div {
  display: block;
  margin-bottom: 30px;
}

.toolboxInner div::-webkit-scrollbar-track {
  background: #00000000;
}

.toolbox::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 3px;
  background-color: #cecece;
}

