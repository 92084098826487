.rb-field-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.rb-field text {
  fill: #000000;
}

.rb-field rect {
  stroke: #ffffff;
  stroke-width: 0;
}

.rb-field:hover rect {
  stroke-width: 2px;
}

.rb-field-input {
  cursor: text;
}

.rb-field-dropdown {
  cursor: pointer;
}

.rb-field-input-input {
  pointer-events: auto;
  background: #ffffff00;
  border: none;
  outline: medium;
  touch-action: manipulation;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  z-index: 10000;
}

.rb-field-dropdown-holder {
  pointer-events: auto;
  display: block;
  opacity: 0;
  transform: translate(0, -6px);
  transition: transform 0.2s ease, opacity 0.2s ease;
  z-index: 10000;
  background: #ffffff;
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.rb-field-dropdown-option {
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  vertical-align: center;
  background: #ffffff;
  color: #333333;
  transition: background-color 0.2s ease;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 10px;
}

.rb-field-dropdown-option:hover {
  background: #eeeeee;
}
