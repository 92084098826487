.rb-block-holder {
  pointer-events: auto;
}

.rb-block-holder * {
  font-size: 14px;
}

.rb-block-holder text {
  fill: #ffffff;
  pointer-events: none;
  user-select: none;
}

.rb-block-holder path {
  cursor: grab;
}
