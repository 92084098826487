.detailBody {
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 30px;
}

.detailBody::-webkit-scrollbar {
  width: 6px;
  background: #00000000;
}

.detailBody::-webkit-scrollbar-track {
  background: #00000000;
}

.detailBody::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 3px;
  background-color: #cecece;
}

.spiltLine {
  width: 100%;
  height: 2px;
  margin: 20px 0 10px;
}

.spiltLine div{
  position: absolute;
  height: 2px;
  left: 20px;
  right: 20px;
  background: #dddddd;
}

.holder {
  width: 100%;
  min-width: 300px;
  height: 44px;
}

.keyText {
  display: inline-block;
  width: 100px;
  height: 14px;
  margin-top: 20px;
  margin-left: 20px;

  color: #333333;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.valueText {
  display: inline-block;
  /*width: 56px;*/
  /*height: 14px;*/
  margin-top: 20px;
  margin-right: 25px;

  color: #999999;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}
