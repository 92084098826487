.node {
  position: relative;
  width: 100%;
  height: 100%;
}

.holder {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: -10px;
}

.connectors {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 38px;
  top: 0;
  bottom: 0;
  cursor: default;
}

.note {
  position: absolute;
  width: 22px;
  height: 22px;
  right: 48px;
  top: 10px;
  padding: 4px;
  border-radius: 10px;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.note:hover {
  background-color: #e3e3e3;
}

.note:active {
  background-color: #cccccc;
}

.img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 38px;
  right: 38px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
  /*filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));*/
  text-align: center;
}

.img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  text-align: center;
}

.needSize {
  width: 94px;
  height: 94px;
}

.logicHolder {
  position: relative;
  width: 100%;
  height: 100%;
}

.logic {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0;
  bottom: 0;
  padding: 11px;
  border-radius: 10px;
  pointer-events: none;
}

.logicBg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.logicBgTitle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 34px;
  background: #ffffff;
  border-top-left-radius: 34px;
  border-top-right-radius: 34px;
  padding: 10px 24px;
  font-size: 16px;
  color: #42A4FF;
  font-weight: bold;
}

.logicBgGraph {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0;
  bottom: 0;
  background: #ffffff;
  border-radius: 20px;
}

.logicScale {
  position: absolute;
  left:   0;
  top:    0;
  right:  0;
  bottom: 0;
}

.logicScale p {
  position: absolute;
}
.logicScale p:nth-child(1) {
  top: 0;
  width: 100%;
  height: 10px;
  cursor: ns-resize;
}
.logicScale p:nth-child(2) {
  right: 0;
  width: 10px;
  height: 100%;
  cursor: ew-resize;
}
.logicScale p:nth-child(3) {
  bottom: 0;
  width: 100%;
  height: 10px;
  cursor: ns-resize;
}
.logicScale p:nth-child(4) {
  left: 0;
  width: 10px;
  height: 100%;
  cursor: ew-resize;
}

.logicBgImg {
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 10px;
  border: 2px dashed #42A4FF;
}

.logicBgImg p {
  width: 45px;
  height: 45px;
  position: absolute;
  margin: -2px;
}
.logicBgImg p:nth-child(1){
  border-top-left-radius: 10px;
  border-top: 3px solid #42A4FF;
  border-left: 3px solid #42A4FF;
}
.logicBgImg p:nth-child(2){
  right: 0;
  border-top-right-radius: 10px;
  border-top: 3px solid #42A4FF;
  border-right: 3px solid #42A4FF;
}
.logicBgImg p:nth-child(3){
  bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom: 3px solid #42A4FF;
  border-left: 3px solid #42A4FF;
}
.logicBgImg p:nth-child(4){
  right: 0;
  bottom: 0;
  border-bottom-right-radius: 10px;
  border-bottom: 3px solid #42A4FF;
  border-right: 3px solid #42A4FF;
}

.logicCenter {
  display: inline-block;
  position: absolute;
  top: -54px;
  left: 38px;
  right: 38px;
  bottom: -20px;
}

.logicCenter.mask {
  background-color: #45a5ff;
}

.logicCenter img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.OptionPaper {
}

.graph {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.portHolder {
  flex: 1;
  position: relative;
}

.port {
  display: block;
  width: 34px;
  height: 20px;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);

  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  transition: background-color 0.1s ease;

  border: 2px solid #00000000;
}

.port:hover {
  border-color: #ff8652;
}

.port p {
  display: none;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  border-radius: 50%;
  border: 2px solid #ff8652;
}

.port span {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
}

.portRight p {
  right: -7px;
}

.portLeft p {
  left: -7px;
}

.portCanConnect:hover p {
  display: block;
}

.portRight {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.portLeft {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.label {
  position: absolute;
  height: 22px;
  width: 100%;
  bottom: -38px;
  margin: 0 auto;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.logicPad.disable {
  background-color: #fffffefa;
}

.logicPad.disable img {
  opacity: 0.5;
}

.logicPad.disable .label {
  color: #666666;
}
