.start {
  width: 100%;
  height: 100%;
  min-height: 880px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f9f9f9;
  min-width: 1080px;
}

.link {
  text-decoration: none;
}

.header {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 80px;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
}

.title {
  width: 220px;
  height: 28px;
  margin-left: 40px;

  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  /*letter-spacing: 0%;*/
  text-align: left;
}

.info {
  display: flex;
  height: 100%;
  margin-right: 40px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.profile {
  display: inline-block;
  width: 44px;
  height: 44px;
  margin-right: 15px;
}

.user {
  display: inline-block;
  height: 28px;

  color: #666666;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.project {
  width: 100%;
}

.createProject {
  margin-top: 57px;
  margin-left: 197px;
}

.createProjectTitle {
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.blueBar1 {
  width: 4px;
  height: 19px;
  background: #42a4ff;
}

.createProjectText {
  width: 80px;
  height: 28px;
  margin-left: 11px;

  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
}

.imgBtnHolder {
  display: none;
  position: absolute;
  left: 15px;
  right: 15px;
  top: 36px;
  bottom: 36px;
}

.imgBtnHolder img {
  position: absolute;
  width: 28px;
  height: 28px;
  object-fit: cover;
}

.imgBtnHolder img:hover {
  cursor: pointer;
}

.upload {
  top: 50%;
  left: 50%;
  width:  28px;
  height: 28px;
  transform: translate(-50%, -50%);
}

.dialogImg:hover span {
  display: block;
}

.divider {
  height: 1px;
  background: #3d3d3d;
}

.clsProject {
  display: -webkit-flex;
  display: flex;
  height: 211px;
  margin-top: 53px;
  margin-right: 198px;
  justify-content: flex-start;
}

.myProject {
  margin-top: 74px;
  margin-left: 197px;
}

.myProjectTitle {
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.blueBar2 {
  width: 4px;
  height: 19px;
  background: #42a4ff;
}

.myProjectText {
  width: 80px;
  height: 28px;
  margin-left: 11px;

  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
}

.historyProject {
  width: 100%;
  margin-top: 53px;
  margin-right: 198px;
}



.OptionPaper {
}

.pLine {
  position: relative;
  height: 22px;
  width: 370px;
  margin: 20px 10px;
}

.pLine img {
  display: inline-block;
  margin: 2px 4px 2px 0;
  object-fit: cover;
}

.pLine p {
  position: absolute;
  display: inline-block;
  height: 22px;
  line-height: 22px;
}

.dialogLine {
  position: relative;
  margin: 20px 0;
}

.dialogLineName {
  display: inline-block;
  line-height: 40px;
  height: 40px;
  font-size: 17px;
  color: #3d3d3d;
}

.dialogImg {
  display: inline-block;
  position: absolute;
  left: 85px;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  background: #dddddd;
}

.currentImg {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  object-fit: cover;
}

.dialogImg div {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px;
  transition: background 0.2s ease;
}

.dialogImg:hover div {
  background: #00000033;
}
