.node-border {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #529bf1;
  z-index: 1;
  pointer-events: none;
}

.node-border div {
  position: absolute;
  width: 4px;
  height: 4px;
  border: 1px solid #529bf1;
  background: #f5f5f5;
}

.node-border div:nth-child(1) {
  bottom: -4px;
  right: -4px;
}

.node-border div:nth-child(2) {
  top: -4px;
  right: -4px;
}

.node-border div:nth-child(3) {
  bottom: -4px;
  left: -4px;
}

.node-border div:nth-child(4) {
  top: -4px;
  left: -4px;
}

.x6-node-selected .node-border {
  display: block;
}

.x6-edge:hover path:nth-child(2) {
  stroke: #3d96ff;
  stroke-width: 4px;
}

.x6-edge-selected path:nth-child(2) {
  stroke: #3d96ff;
  stroke-width: 4px;
}
/* bugfix 选中线条颜色也要0.5透明度 */
.x6-edge:hover.disable path:nth-child(2) {
  stroke: #71b0ff;
  stroke-width: 4px;
}

/* bugfix 选中线条颜色也要0.5透明度 */
.x6-edge-selected.disable  path:nth-child(2) {
  stroke: #9cc6ff;
  stroke-width: 4px;
}

.x6-graph-svg {
  /*overflow: visible;*/
}

.device.disable {
  /*mask: url("./mask.svg");*/
  background-color: #fffffefa;
}

.device.disable img {
  opacity: 0.5;
}

.device.disable .label {
  color: #666666;
}

.logicPad.disable{
  background-color: #f5f5f5;
  opacity: 0.5;
}
