
.SplitLayout {
  width: 100%;
  height: 100%;
}

.top {
  position: absolute;
  width: 100%;
  top: 0;
  overflow: hidden;
}

.bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  overflow: hidden;
}

.line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #dddddd;
  z-index: 100;
}

.line::before {
  content: '';
  position: absolute;
  width: 100%;
  top: -2px;
  bottom: -2px;
}

.line:hover{
  cursor: ns-resize;
}
