.EnterExist {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 224px;
  margin: 0 60px 60px 0;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 40, 183, 0.08);
  background: #aaaaaa;
  cursor: pointer;
}

.EnterExist:hover {
  filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.15));
}

.EnterExist:active {
  filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.15));
}

.coverDiv {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000000;
  border-radius: 12px;
  transition: background 0.2s ease;
}

.EnterExist:active p {
  background: #00000033;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.info {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 59px;
  background: #ffffff;
}

.name {
  display: block;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;

  color: #000000;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.date {
  display: block;
  height: 17px;
  margin: 5px 10px 0 10px;

  color: #666666;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  overflow: hidden;
}

.state {
  width: 62px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  /*background: #FF842C;*/
}

.stateText {
  display: block;
  color: #ffffff;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 600;
  height: 14px;
  line-height: 14px;
  margin: 6px auto;
  /*text-align: center;*/
}
