.App {
  width: 100%;
  height: 100%;
  text-decoration: none;
  overflow: auto;
}

.holder {
  width: 100%;
  height: 100%;
}
