.ModalTitle {
  position: relative;
  height: 38px;
  background: #f0f1f5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.text {
  display: inline-block;
  margin: 12px 16px;
  height: 14px;
  left: 4.32%;
  right: 72.97%;
  top: calc(50% - 14px / 2);

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;
  text-align: center;

  color: #5c5f66;
}

.closeBtn {
  width: 18px;
  height: 18px;
  margin: 10px;
}
