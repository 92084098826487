.MuiTabs-scroller {
  height: 35px;
}

.MuiTabs-flexContainer {
  height: 35px;
}

.MuiTabs-scrollButtons {
  height: 35px;
}

.MuiTabs-root .Mui-disabled {
  color: #888888;
}