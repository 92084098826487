.Login {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-flex;
  min-width: 1200px;
}

.bg {
  width: 57%;
  height: 100%;
  background: linear-gradient(119.02deg, #070e41 0%, #020053 72.36%);

  display: flex;
}

.bgImage {
  width: 700px;
  height: 700px;
  margin: auto;
}

.link {
  text-decoration: none;
}

.holder {
  /*margin-left: 57%;*/
  width: 43%;
  height: 100%;
  background: #ffffff;
}

.form {
  width: 400px;
  height: 600px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-60%, -50%);
}

.title {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  color: #051037;
  text-shadow: 0 4px 4px #44000000;
}

.secTitle {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #051037;
}

.account {
  margin: 20px 0 20px 0;
}

.password {
  margin: 20px 0 20px 0;
}

.accountField {
}

.passwordField {
}

.loginBtn {
}

.forgetBtn {
}

.registerBtn {
}
