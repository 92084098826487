.Editor {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1620px;
}

.header {
  width: 100%;
  height: 60px;
  background: #3d3d3d;
  z-index: 6;
}

.toolbar {
  display: inline-block;
  height: 48px;
  margin: 6px;
  overflow: hidden;
}

.divider {
  display: inline-block;
  width: 2px;
  height: 28px;
  margin: 6px 8px;

  opacity: 0.3;
  background: #eeeeee;
}

.title {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  padding: 0 8px;
}

.title span {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  text-align: center;

  background: #3d3d3d;
  color: #ffffff;
}

.title input {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  text-align: center;

  background: #3d3d3d;
  color: #ffffff;
  border: none;
  outline: none;
}

.sendBtn {
  position: absolute;
  top: 12px;
  right: 16px;
}

.main {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
}

.more {
  font-size: 68px;
  color: #aaaaaa;
  text-align: center;
  line-height: 60px;
  margin-top: 200px;
}

.deviceBody {
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
}

.deviceList {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.splitLine {
  background: #dddddd;
}

.splitLineHorizontal {
  height: 1px;
  width: 100%;
}

.blockList {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #f5f5f5;
}

.deviceDetail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rightSplitLine {
  position: absolute;
  top: 600px;
  right: 0;
  left: 0;
  height: 1px;
  background: #dddddd;
}

.codeEditor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.codeEditorContent {
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  bottom: 0;
}

.betterScroll::-webkit-scrollbar {
  width: 6px;
  background: #00000000;
}

.betterScroll::-webkit-scrollbar-track {
  background: #00000000;
}

.betterScroll::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 3px;
  background-color: #cecece;
}

.sfBar {
  padding: 8px 8px;
  height: 32px;
  border-bottom: 1px solid #dddddd;
}

.searchBar {
}

.filterBar {
}

.deviceGridHolder{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.deviceGrid {
  width: 100%;
  margin-bottom: 20px;
}

.deviceItem {
  position: absolute;
  border-radius: 18px;
  width: 80px;
}

.deviceImg {
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 12px;
  transition: background 0.2s ease;
}

.deviceImg:hover {
  background: #dfe0e3;
  cursor: pointer;
}

.deviceImg img {
  margin-top: 40px;
  transform: translateY(-50%);
  pointer-events: none;
}

.needSize {
  width: 80px;
  height: 80px;
}

.deviceName {
  margin-top: 10px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #444444;
}

.listTitle {
  display: flex;
  height: 50px;
  border-bottom: 1px solid #dddddd;
  background: #f9fafb;
  white-space: nowrap;
  align-items: center;
}

.listTitle h1 {
  flex-grow: 1;
}

.listTitle button {
  background: #f4f4f4;
  border-radius: 8px;
  margin: 0 4px;
  transition: background-color 0.2s ease-in-out;
}

.listTitle .language {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  padding: 10px;
  margin: 0 8px 0 0;
  background: #eeeeee;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  color: #666666;
  transition: all 0.1s ease-in-out;
}

.listTitle .language:hover {
  background: #e3e3e3;
}

.listTitle .language:active {
  background: #d8d8d8;
}

.listTitle button:hover {
  background: #e3e3e3;
}

.listTitle button:active {
  background: #d8d8d8;
}

.listTitle span {
  display: inline-block;
  height: 14px;
  margin: 18px;
  line-height: 14px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.deviceBarIcons {
  position: absolute;
  width: 92px;
  height: 40px;
  right: 6px;
  top: 6px
}

.deviceBarIcons:nth-child(0) {
  margin-right: 4px;
}

.containerHolder {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  overflow: visible;
}

.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.toolBtn {
  position: absolute;
  width: 38px;
  height: 38px;
  padding: 4px;
  right: 35px;
}

.toolBtn:hover {
  cursor: pointer;
}

.svgBtn {
  position: absolute;
  width: 38px;
  height: 38px;
  padding: 4px;
  top: 25px;
}

.svgBtn svg {
  width: 38px;
  height: 38px;
}

.svgBtn path{
  stroke: #D9D9D9;
}

.svgBtn:hover path{
  stroke: #B0B0B0;
}

.svgBtn:active path{
  stroke: #999999;
}

.rubbishBtn {
  right: 25px;
  z-index: 1000000;
}

.rubbishBtn:hover{
  cursor: no-drop;
}

.scaleBtnDown {
  right: 80px;
}

.scaleBtnUp {
  right: 135px;
}

.centerBtn {
  right: 190px;
}

.portHover {
  position: absolute;
  width: 260px;
  height: 176px;
  z-index: 10;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 12px #dddddd;
  transform: translate(-50%, -100%);
}

.portHover span {
  display: inline;
  position: absolute;

  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.portHoverKey {
  left: 20px;
  color: #444444;
}

.portHoverValue {
  left: 120px;
  color: #999999;
}

.flyout {
  position: absolute;
  left: 0;
  right: 0;
  top: 87px;
  bottom: 0;
}
