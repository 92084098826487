/*@font-face {*/
/*  font-family: 'Material Icons';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: url(MaterialIcons-Regular.eot); !* For IE6-8 *!*/
/*  src: local('Material Icons'),*/
/*  local('MaterialIcons-Regular'),*/
/*  url(MaterialIcons-Regular.woff2) format('woff2'),*/
/*  url(MaterialIcons-Regular.woff) format('woff'),*/
/*  url(MaterialIcons-Regular.ttf) format('truetype');*/
/*}*/
/*.material-icons {*/
/*  font-family: 'Material Icons';*/
/*  font-size: 24px;  !* Preferred icon size *!*/
/*  display: inline-block;*/
/*  line-height: 1;*/
/*  text-transform: none;*/
/*  letter-spacing: normal;*/
/*  word-wrap: normal;*/
/*  white-space: nowrap;*/
/*  direction: ltr;*/

/*  !* Support for all WebKit browsers. *!*/
/*  -webkit-font-feature-settings: 'liga';*/
/*  -webkit-font-smoothing: antialiased;*/
/*  !* Support for Safari and Chrome. *!*/
/*  text-rendering: optimizeLegibility;*/
/*  !* Support for Firefox. *!*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  !* Support for IE. *!*/
/*  font-feature-settings: 'liga';*/
/*}*/

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(MaterialIconsOutlined-Regular.woff2) format('woff2');
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

/*@font-face {*/
/*  font-family: 'Material Icons Round';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: url(MaterialIconsRound-Regular.woff2) format('woff2');*/
/*}*/
/*.material-icons-round {*/
/*  font-family: 'Material Icons Round';*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*  font-size: 24px;*/
/*  line-height: 1;*/
/*  letter-spacing: normal;*/
/*  text-transform: none;*/
/*  display: inline-block;*/
/*  white-space: nowrap;*/
/*  word-wrap: normal;*/
/*  direction: ltr;*/

/*  !* Support for all WebKit browsers. *!*/
/*  -webkit-font-feature-settings: 'liga';*/
/*  -webkit-font-smoothing: antialiased;*/
/*  !* Support for Safari and Chrome. *!*/
/*  text-rendering: optimizeLegibility;*/
/*  !* Support for Firefox. *!*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  !* Support for IE. *!*/
/*  font-feature-settings: 'liga';*/
/*}*/

/*@font-face {*/
/*  font-family: 'Material Icons Sharp';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: url(MaterialIconsSharp-Regular.woff2) format('woff2');*/
/*}*/
/*.material-icons-sharp {*/
/*  font-family: 'Material Icons Sharp';*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*  font-size: 24px;*/
/*  line-height: 1;*/
/*  letter-spacing: normal;*/
/*  text-transform: none;*/
/*  display: inline-block;*/
/*  white-space: nowrap;*/
/*  word-wrap: normal;*/
/*  direction: ltr;*/

/*  !* Support for all WebKit browsers. *!*/
/*  -webkit-font-feature-settings: 'liga';*/
/*  -webkit-font-smoothing: antialiased;*/
/*  !* Support for Safari and Chrome. *!*/
/*  text-rendering: optimizeLegibility;*/
/*  !* Support for Firefox. *!*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  !* Support for IE. *!*/
/*  font-feature-settings: 'liga';*/
/*}*/

/*!* fallback *!*/
/*@font-face {*/
/*  font-family: 'Material Icons Two Tone';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: url(MaterialIconsTwoTone-Regular.woff2) format('woff2');*/
/*}*/
/*body {*/
/*  --google-font-color-materialiconstwotone:none;*/
/*}*/
/*.material-icons-two-tone {*/
/*  font-family: 'Material Icons Two Tone';*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*  font-size: 24px;*/
/*  line-height: 1;*/
/*  letter-spacing: normal;*/
/*  text-transform: none;*/
/*  display: inline-block;*/
/*  white-space: nowrap;*/
/*  word-wrap: normal;*/
/*  direction: ltr;*/

/*  !* Support for all WebKit browsers. *!*/
/*  -webkit-font-feature-settings: 'liga';*/
/*  -webkit-font-smoothing: antialiased;*/
/*  !* Support for Safari and Chrome. *!*/
/*  text-rendering: optimizeLegibility;*/
/*  !* Support for Firefox. *!*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  !* Support for IE. *!*/
/*  font-feature-settings: 'liga';*/
/*}*/
