
.HorizontalSplitLayout {
  width: 100%;
  height: 100%;
}

.left {
  position: absolute;
  height: 100%;
  left: 0;
  overflow: hidden;
}

.middle {
  position: absolute;
  height: 100%;
  overflow: hidden;
}

.right {
  position: absolute;
  height: 100%;
  right: 0;
  overflow: hidden;
}

.line {
  position: absolute;
  height: 100%;
  width: 1px;
  background: #dddddd;
  z-index: 0;
}

.line::before {
  content: '';
  position: absolute;
  height: 100%;
  left: -2px;
  right: -2px;
}

.line:hover {
  cursor: ew-resize;
}

.lineLeft {
}

.lineRight {
}
