.ToolBtn {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 8px;

  border-radius: 6px;
  background: #3d3d3d;
  transition: all 0.2s ease;
  cursor: pointer;
}

.ToolBtn:hover {
  background: #222222;
}

.ToolBtn:active {
  background: #42a4ff;
}

.ToolBtn::selection {
  background: #42a4ff;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 4px 12px 0 12px;
}

.iconFlip {
  width: 24px;
  height: 24px;
  margin: 4px 12px 0 12px;
  transform: rotateY(180deg);
}

.text {
  width: 100%;
  height: 12px;
  margin-bottom: 4px;
  text-align: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
}
